import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';
import useTranslations from '../hooks/useTranslations';
import Layout from '../components/Layout';
import VideoBackground from '../components/VideoBackground';
import navigatorLogo from '../images/logos/NavigatorLogo.svg';
import viceXRenaissance from '../images/logos/LockUp.svg';
import { Store } from '../Store';
import { setMakeSelection } from '../actions/appActions';
import CtaButton from '../components/CtaButton';
import SEO from '../components/seo';


function IndexPage(props) {
  const {
    videoFile,
    backupGif,
    mainImage
  } = props.data.craft.entry;
  const { seoTitle, seoDescription, seoImage, seoKeywords } = props.data.craft.globals.globalSeo;
  const language = props.pageContext.locale;

  const { startYourJourney } = useTranslations();
  const { dispatch: appDispatch } = useContext(Store);

  function handleStartYourJourneyClick() {
    appDispatch(setMakeSelection(true));
  }

  return (
    <Layout location={props.location} homepage={true}>
      <SEO
        lang={language}
        title={seoTitle}
        description={seoDescription}
        keywords={seoKeywords ? seoKeywords.split(',').map(item => item.trim()) : ''}
        image={seoImage[0].url || ''}
      />
      <div className="homepage">
        <VideoBackground videoSrc={videoFile[0].url} poster={mainImage[0].url} backupGif={backupGif[0].url} />
        <div className="homepage__section"></div>
        <div className="homepage__section">
          <div className="homepage__logos">
            <img className="homepage__vice-partner-logo" src={viceXRenaissance} alt="VICE x Renaissance Hotels" />
            <img className="homepage__navigator-logo" src={navigatorLogo} alt="Navigator" />
          </div>
        </div>
        <div className="homepage__section">
          <CtaButton
            text={startYourJourney}
            onClick={handleStartYourJourneyClick}
            arrowDirection="down"
          />
        </div>
      </div>
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query HomepageQuery($locale: String!) {
    craft {
      entry(section:[homepage], site: $locale) {
        ...on Craft_Homepage {
          id
          title
          videoFile {
            id
            title
            url
          }
          backupGif {
            id
            title
            url
          }
          mainImage {
            id
            title
            url
          }
        }
      }
      globals(site: $locale) {
        globalSeo {
          seoTitle
          seoDescription
          seoKeywords
          seoImage {
            id
            url
          }
        }
      }
    }
  }
`;
