import React, { useRef, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import './video-background.scss';
import classNames from 'classnames';
import BackgroundImage from '../BackgroundImage';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import 'objectFitPolyfill';

const SET_AUTOPLAY_ALLOWED = 'SET_AUTOPLAY_ALLOWED';

function setAutoplayAllowed(bool) {
  return {
    type: SET_AUTOPLAY_ALLOWED,
    payload: bool
  }
}

const initialState = {
  autoplayAllowed: true,
  showBackupGif: false,
  displayBackground: false,
}

function reducer(state, action) {
  switch(action.type) {
    case SET_AUTOPLAY_ALLOWED:
      return {
        ...state,
        autoplayAllowed: action.payload,
        showBackupGif: action.payload ? false : true,
        displayBackground: true,
      }
    default:
      return state;
  }
}

function VideoBackground({ videoSrc, poster, backupGif }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { autoplayAllowed, showBackupGif, displayBackground} = state;
  const videoRef = useRef();

  useEffect(() => {
    const wrapper = videoRef.current;
    const video = wrapper.children[0];
    const promise = video.play();

    objectFitPolyfill(video);

    if (promise !== undefined) {
      promise.then(_ => {
        console.log('Autoplay started!');
        dispatch(setAutoplayAllowed(true));
      }).catch((error) => {
        console.log('Autoplay error', error);
        dispatch(setAutoplayAllowed(false));
      });
    }
  }, []);

  const videoBackgroundClasses = classNames('video-background', {
    'video-background--loaded': displayBackground
  });

  return (
    <TransitionGroup component={null}>
      <CSSTransition
        key={videoSrc}
        classNames="video-fade"
        timeout={200}
      >
        <div className={videoBackgroundClasses}>
          {autoplayAllowed && 
            <div ref={videoRef} className="video-wrapper w-full h-full" dangerouslySetInnerHTML={{ __html: `
              <video
                loop
                muted
                autoplay
                playsinline
                data-object-fit
                poster="${poster}"
              >
                <source src="${videoSrc}" />
              </video>`}}
            />    
          }
      
          {showBackupGif && 
            <BackgroundImage src={backupGif} />
          }
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

VideoBackground.propTypes = {
  videoSrc: PropTypes.string,
  poster: PropTypes.string,
}

export default VideoBackground;